//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import itineraryList from "@/lib/data-service/flight/flight_domestic_buyer_manage_order_itinerary_list";
import scrollTop from "@/lib/common-service/scrollTop";
import FligthTime from "./component/flightTimeBox";
import { captureRejectionSymbol } from "events";

export default {
  data() {
    return {
      timeUpbox: null,
      timeUpboxHeight: null,
      searchForm: {
        currentPage: 1,
        pageSize: 10
      },
      list: [],
      totalPage: 0,
      tableData: [],
      tabelNav: [
        { text: "全部订单", statu: true, val: 1 },
        { text: "即将到期", statu: false, val: 2 },
        { text: "未申请", statu: false, val: 3 },
        { text: "不能申请", statu: false, val: 4 }
      ],
      loading: false,
      applyBatchList: []
    };
  },
  components: { FligthTime },
  activated() {
    this.getList();
  },
  methods: {
    //获取列表
    getList() {
      let data = this.searchForm;
      this.loading = true;
      itineraryList(data).then(res => {
        this.loading = false;
        this.tableData = res.pageResult.pageData;
        this.totalPage = res.pageResult.totalCount;
        scrollTop(500);
      });
    },
    checkboxInit(row, index) {
      if (row.status === "未申请" || row.status === "即将到期") {
        return true;
      } else {
        return false;
      }
    },
    //表格类型搜索
    getNavList(val, index) {
      let data = this.searchForm;
      this.tabelNav.map(item => {
        item.statu = false;
      });
      this.$nextTick(() => {
        this.tabelNav[index].statu = true;
      });
      data.status = val.val;
      this.getList();
    },
    changeList(val) {
      this.applyBatchList = val;
    },
    handlePageSize(val) {
      this.searchForm.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getList();
    },
    offHeight(e) {
      this.timeUpboxHeight = e.target.offsetParent.offsetHeight;
      // e.fromElement.localName == "td" ? e.fromElement.offsetHeight : 0;
    },
    //重置
    recharge() {
      this.searchForm = {};
      this.searchForm.currentPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    setOrdersNo(val) {
      this.$router.push({
        name: "air-ticket-admin-airDetails",
        query: {
          ordersNo: val
        }
      });
    },
    apply(val, itineraryNo, ticketNumberList) {
      let obj = {
        psgNo: val.psgNo,
        ticketNumber: val.ticketNumberList[0]
      };
      obj = JSON.stringify(obj);
      this.$router.push({
        name: "air-ticket-admin-strokeOrderInfo",
        query: {
          // psgNo: val,
          // itineraryNo: itineraryNo,
          // ticketNumberList: ticketNumberList.join(",")
          dataInfo: obj
        },
        params: { refresh: 1 }
      });
    },
    applyBatch(val) {
      if (val.length == 0) return;

      let dataArr = val.map(item => {
        return {
          psgNo: item.psgNo,
          ticketNumber: item.ticketNumberList[0]
        };
      });
      dataArr = JSON.stringify(dataArr);
      // let arr = val.map(item => {
      //   return item.psgNo;
      // });
      // let ticketNumberList = val.map(item => {
      //   return item.ticketNumberList;
      // });
      this.$router.push({
        name: "air-ticket-admin-strokeOrderInfo",
        query: {
          // psgNoAll: arr.join(","),
          // ticketNumberList: ticketNumberList.join(",")
          dataInfo: dataArr
        }
      });
    }
  }
};

const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 行程单列表
const recordCount = (data) => {
let pParameter = {
    method: 'post',
    // urlSuffix: '/flight/domestic/buyer/manage/itineraryOrder/list',
    urlSuffix:"/buyer/dom/manage/itinerary/list",
    data:data
  }

  return __request(pParameter)
}
export default recordCount

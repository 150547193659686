//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  props: {
    listLength: Number,
    timeUpboxHeight: Number,
    detailList: Array,
    timeUpbox: Number,
    indexShow: Number
  },
  methods: {}
};
